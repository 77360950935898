<template>
  <div style="background: #f5f5f5;height: 100%;">
    <van-nav-bar
      v-show="!pleshow"
      :title="customerName +' 推荐的客户'"
      left-arrow
      :fixed="true"
      :z-index="66"
      @click-left="back"
    >
      <template #right>
        <img class="tuosimg" @click.stop="toadd"  style="margin-right: 18px;" src="../../assets/img/tuojia.png" alt="">
        <img class="tuosimg" @click.stop="toticheng()" src="../../assets/img/tuojia2.png" alt="">
        <!-- <van-icon color="#07c160" name="plus" />
        <span style="color: #07c160;" class="liusui">添加下线</span> -->
      </template>
    </van-nav-bar>
    <div style="padding-top: 46px;" v-show="!pleshow">
      <div class="heds">
      
        <van-row style="font-size: 13px;">
          <van-col span="2" class="ls">#</van-col>
          <van-col span="6" class="ls">车牌</van-col>
          <van-col span="4" class="rs">车主姓名</van-col>
          <van-col span="6" class="ls">电话号码</van-col>
          <van-col span="6" class="rs">注册时间</van-col>
        </van-row>
        <div v-for="(item,idx) in list" :key="idx">
          <van-swipe-cell>
            <van-row v-for="(items,idxs) in item.tokerCarInfoVOList" :key="idxs">
              <van-col span="2" class="ls">{{idx+(idxs+1)}}</van-col>
              <van-col span="6" class="ls">{{items.carNo}}</van-col>
              <van-col span="4" class="rs">{{items.customerName}}</van-col>
              <van-col span="6" class="ls">{{items.phone}}</van-col>
              <van-col span="6" class="rs" @click.native="totuijianxq(items)">
                <span style="vertical-align: middle">{{items.createdDate.substring(0,10)}}</span>
                <van-icon style="vertical-align: middle;margin-top: 1px;" name="arrow" />
              </van-col>
            </van-row>
            <template #right>
              <van-button
                style="margin: 0;height: 100%;"
                square
                text="删除"
                type="danger"
                class="delete-button"
                @click.native="toshanchu(item)"
              />
            </template>
          </van-swipe-cell>
        </div>
      </div>
    </div>
    <van-popup
      v-model="show"
      :close-on-click-overlay="false"
      closeable
      v-show="!pleshow"
      style="z-index: 99 !important;"
    >
      <div class="hes1">新增客户车辆</div>
      <div class="hes2">
        <span class="hes_lis">车牌</span>
        <input @click="getple" class="ispus" type="text" readonly v-model="carNo" />
      </div>
      <div class="hes2">
        <span class="hes_lis">车主姓名</span>
        <input type="text" class="ispus" v-model="name" />
      </div>
      <div class="hes2">
        <span class="hes_lis">手机号码</span>
        <input type="tel" class="ispus" v-model="phone" />
      </div>
      <div class="bott">
        <van-button type="primary" size="small" @click="addxiaxian">添 加</van-button>
        <van-button type="danger" @click="show = false" size="small">退 出</van-button>
      </div>
    </van-popup>

    <carPlate ref="ple" v-show="pleshow" :plate_number="carNo" @getPlate="getPlate"></carPlate>
  </div>
</template>

<script>
import userwei from "../../api/user";
import carPlate from "../../components/carplate.vue";
import { Dialog } from "vant";
export default {
  components: {
    carPlate
  },
  data() {
    return {
      list: [],
      show: false,
      pleshow: false,
      carNo: "",
      name: "",
      phone: "",
      customerId: this.$route.query.customerId,
      customerName: this.$route.query.customerName,
      offId: this.$route.query.offId,
      gid: this.$route.query.gid,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      nameList: {}
    };
  },
  methods: {
    //添加下线
    toadd() {
      this.show = true;
    },
    //打开键盘
    getple() {
      this.pleshow = true;
      this.$refs.ple.clickShowKeyboard();
    },

    //删除
    toshanchu(items) {
      console.log(items);
      // this.carNo = items.carNo;
      Dialog.confirm({
        title: "",
        message:
          "确实要删除车牌【" + items.tokerCarInfoVOList[0].carNo + "】吗？"
      })
        .then(() => {
          var data = {
            customerId: items.tokerCarInfoVOList[0].customerId,
            gid: items.gid
          };
          userwei.tokerdelete(data).then(res => {
            if (res.code == 200) {
              this.$toast("删除下线车牌成功！");
              this.onLoad();
            }
          });
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
      // this.scshow = true;
    },
    //跳转修改提成
    toticheng() {
      // return
      this.$router.push({
        name: "tuoke_tichengSZ",
        query: {
          customerName: this.customerName,
          offId:this.offId,
          gid:this.gid
        }
      });
    },

    //添加下线
    addxiaxian() {
      var _this = this;
      if (_this.carNo.length < 6) {
        _this.$toast("请填写车牌号码");
        return;
      } else if (_this.name == "" || !_this.name) {
        _this.$toast("请输入您的姓名");
        return;
      } else if (_this.phone == "" || !_this.phone) {
        _this.$toast("请输入手机号码");
        return;
      } else if (!/^1[23456789]\d{9}$/.test(_this.phone)) {
        _this.$toast("手机号码格式错误");
        return;
      }
      var data = {
        carNo: this.carNo,
        customerId: this.offId,
        flag: true,
        garageName: this.shangjia.name,
        gid: this.shangjia.id,
        name: this.name,
        phone: this.phone
      };
      userwei.recommended(data).then(res => {
        if (res.code == 200) {
          _this.$toast("添加下线车牌成功！");
          this.carNo = "";
          this.name = "";
          this.phone = "";
          this.show = false;
          this.onLoad();
        }
      });
    },
    //输入车牌
    getPlate(val) {
      console.log(val);
      this.carNo = val;
      this.pleshow = false;
    },
    onLoad() {
      userwei.tuokelonger2({ customerId: this.offId }).then(res => {
        this.list = res.data;
      });
    },

    back() {
      this.$router.go(-1);
    },
    totuijianxq(item) {
      this.$router.push({
        name: "indextwo",
        query: {
          customerId: this.offId,
          carNo: item.carNo,
          customerName: item.customerName,
          phone: item.phone,
          gid: item.gid,
          showisok: "消费"
        }
      });
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {
    // this.creatQrCode();
  }
};
</script>

<style lang="less" scoped>
.heds {
  font-size: 12px;
  //   padding-top: 46px;
  margin: 10px 10px 0 10px;
  background: #fff;
  border-radius: 8px;
  min-height: 220px;
}
.xiao {
  background: red;
  display: inline-block;
  font-size: 12px;
  line-height: 20px;
  padding: 2px 1px;
  color: #fff;

  border-radius: 8px;
}
.ispus{
  width: 140px;
}
.van-col {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.van-row {
  text-align: center;

  line-height: 45px;
  border-bottom: 1px solid #f5f5f5;
}
.van-popup {
  width: 260px;
  font-size: 13px;
  padding: 10px 0 20px 0;
}
.hes1 {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  padding: 10px 0 5px 0;
}
.hes2 {
  overflow: hidden;
  padding-top: 10px;
}
.hes_lis {
  display: inline-block;
  width: 70px;
  text-align: right;
  margin-right: 5px;
}
input {
  border-radius: 3px;
  border: 1px solid #dcdfe6;
  line-height: 30px;
  padding-left: 4px;
}
.van-button {
  margin: 0 5px;
}
.bott {
  text-align: center;
  margin-top: 20px;
  font-size: 15px;
}
.tuosimg{
  width: 25px;
 
}
</style>
<style >
.van-overlay {
  z-index: 68 !important;
}
</style>
